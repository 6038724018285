<template>
    <v-autocomplete
        ref="time"
        v-model="time"
        :items="values"
        :label="label"
        :rules="rules"
        append-icon="mdi-clock-time-four-outline"
        :auto-select-first="true"
    ></v-autocomplete>
</template>

<script>
export default {
    name: 'TimeField',
    props: {
        value: {
            default: '',
        },
        label: {
            default: 'Tidspunkt',
            type: String,
        },
        maxWidth: {
            default: '290px',
            type: String,
        },
        minWidth: {
            default: '290px',
            type: String,
        },
        step: {
            default: 15,
            type: Number,
        },
        rules: {
            default: function () {
                return [];
            },
            type: Array,
        },
    },
    data() {
        const values = [];
        for (let hour = 0; hour <= 23; hour++) {
            for (let minutes = 0; minutes < 60; minutes += this.step) {
                values.push(('0' + hour).slice(-2) + ':' + ('0' + minutes).slice(-2));
            }
        }

        return {
            time: this.value || '00:00',
            picker: false,
            values,
        };
    },
    watch: {
        value: function (value) {
            this.time = value;
        },
        time: function (value) {
            this.$emit('input', value);
        },
    },
};
</script>
