<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation style="max-width: 800px">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="form.tittel" label="Tittel" :rules="rules.tittel"></v-text-field>
                    </v-col>
                    <v-col cols="12" :sm="erSorgGruppeTreff ? 6 : 3">
                        <v-select
                            v-model="form.aktivitetTypeId"
                            :items="typer"
                            item-value="id"
                            item-text="navn"
                            :rules="rules.typeId"
                            label="Type"
                            :readonly="erSorgGruppeTreff"
                            :disabled="erSorgGruppeTreff"
                        ></v-select>
                    </v-col>
                    <v-col v-if="!erSorgGruppeTreff" cols="12" sm="3">
                        <date-field v-model="form.publisert" label="Publiser"></date-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="3">
                        <date-field v-model="form.start" label="Start" :rules="rules.start"></date-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <time-field v-model="form.start_tid" label="Klokkeslett" :rules="rules.start_tid"></time-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <date-field v-model="form.slutt" label="Slutt" :rules="[...rules.slutt, validateEndDay]"></date-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <time-field v-model="form.slutt_tid" label="Klokkeslett" :rules="[...rules.slutt_tid, validateEndTime]"></time-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="form.sted" label="Sted"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select
                            v-model="form.moteromId"
                            :items="moterom"
                            :loading="henterMoterom"
                            item-value="id"
                            item-text="navn"
                            label="Møterom"
                            no-data-text="Ingen ledige møterom for det aktuelle tidspunktet"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row v-if="!erSorgGruppeTreff">
                    <v-col cols="12" sm="3">
                        <date-field v-model="form.paameldingsfrist" label="Påmeldingsfrist"></date-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field v-model="form.antallPlasser" label="Maks antall plasser"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-switch v-model="form.visDeltakere" label="Vis deltakere"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-switch v-model="form.aapenPaamelding" label="Åpen påmelding"></v-switch>
                    </v-col>
                </v-row>
                <v-row v-if="!erSorgGruppeTreff">
                    <v-col cols="12">
                        <label class="v-label v-label--control">Beskrivelse</label>
                        <redactor v-model="form.beskrivelse"></redactor>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="!form.id" color="primary" @click="save">{{ $t('btn.create') }}</v-btn>
            <v-btn v-if="form.id" color="primary" @click="save">{{ $t('btn.save') }}</v-btn>
            <v-btn v-if="form.id && changed && !saving" text color="primary" v-on:click="cancel">Angre endringer</v-btn>
            <v-btn v-if="form.id" text color="primary" v-on:click="remove">Slett</v-btn>
        </v-card-actions>
        <alert ref="alert"></alert>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';
import TimeField from '@/vendor/noop/components/TimeField.vue';
import Alert from '@/vendor/noop/components/Alert.vue';
import Redactor from '@/vendor/noop/components/Redactor.vue';

export default {
    name: 'ActivityTabGeneral',
    components: {
        DateField,
        TimeField,
        Alert,
        Redactor,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            valid: true,
            changed: false,
            saving: false,

            form: {
                tittel: '',
                aktivitetTypeId: null,
                sted: null,
                moteromId: null,
                start: null,
                start_tid: null,
                slutt: null,
                slutt_tid: null,
                publisert: null,
                paameldingsfrist: null,
                antallPlasser: '',
                visDeltakere: false,
                aapenPaamelding: false,
            },

            typer: [],
            moterom: [],
            henterMoterom: false,

            rules: {
                tittel: [(v) => !!v || 'Tittel må oppgis', (v) => (v && v.length <= 255) || 'Tittel kan maks være 255 tegn'],
                typeId: [(v) => !!v || 'Type må oppgis'],
                start: [(v) => !!v || 'Start må oppgis'],
                start_tid: [(v) => !!v || 'Tidspunkt må oppgis'],
                slutt: [(v) => !!v || 'Slutt må oppgis'],
                slutt_tid: [(v) => !!v || 'Tidspunkt må oppgis'],
            },
        };
    },
    computed: {
        erSorgGruppeTreff: function () {
            return this.form && this.form.type && this.form.type.id == 'SORGGRUPPE';
        },
    },
    watch: {
        'form.tittel': function () {
            this.changed = true;
        },
        'form.aktivitetTypeId': function () {
            this.changed = true;
        },
        'form.sted': function () {
            this.changed = true;
        },
        'form.moteromId': function () {
            this.changed = true;
        },
        'form.start': function (value) {
            this.changed = true;
            if (value) {
                this.form.start = value;
                if (!this.form.slutt || (this.form.start && this.form.slutt.isSame(this.form.start))) {
                    this.form.slutt = value;
                }
            }
            this.updateAvailableRooms();
        },
        'form.start_tid': function () {
            this.changed = true;
            this.updateAvailableRooms();
        },
        'form.slutt': function () {
            this.changed = true;
            this.updateAvailableRooms();
        },
        'form.slutt_tid': function () {
            this.changed = true;
            this.updateAvailableRooms();
        },
    },

    /**
     * created
     */
    created: async function () {
        let response = await this.request({
            method: 'get',
            url: '/aktivitet/type',
        });
        if (response && Array.isArray(response)) {
            this.typer = response;
        }

        if (this.value) {
            this.form = Object.assign(this.form, this.value);
            this.update();
        }
    },

    methods: {
        ...mapActions('api', ['request', 'formatActivity']),
        ...mapActions(['snackbar', 'confirm']),

        /**
         * update
         */
        update: async function (aktivitet) {
            if (aktivitet) {
                this.form = await this.formatActivity(aktivitet);
            }

            this.form.aktivitetTypeId = this.form.type ? this.form.type.id : null;
            this.form.moteromId = this.form.moterom ? this.form.moterom.id : null;

            this.form.start_tid = this.form.start ? this.form.start.format('HH:mm') : '';
            this.form.slutt_tid = this.form.slutt ? this.form.slutt.format('HH:mm') : '';

            window.setTimeout(() => {
                this.valid = true;
                this.changed = false;
                this.saving = false;
            }, 200);
        },

        /**
         * save
         */
        save: async function () {
            if (this.$refs.form.validate()) {
                this.saving = true;

                const data = Object.assign({}, this.form);

                if (!data.moteromId) {
                    delete data.moteromId;
                } else {
                    await this.updateAvailableRooms();

                    let available = false;
                    for (const moterom of this.moterom) {
                        if (moterom.id == data.moteromId) {
                            available = true;
                            break;
                        }
                    }
                    if (!available) {
                        this.$refs.alert.open('Feil', 'Møterommet er ikke ledig i det aktuelle tidsrommet. Velg et annet rom.');
                        this.form.moteromId = null;
                        this.form.moterom = null;
                        return;
                    }
                }

                if (data.publisert) {
                    data.publisert = data.publisert.format('DD.MM.YYYY HH:mm');
                }

                if (data.start_tid && data.start) {
                    let hour, minutes;
                    [hour, minutes] = data.start_tid.split(':');
                    data.start.set({ hour: hour, minute: minutes });
                }
                data.start = data.start ? data.start.format('DD.MM.YYYY HH:mm') : null;

                if (data.slutt_tid && data.slutt) {
                    let hour, minutes;
                    [hour, minutes] = data.slutt_tid.split(':');
                    data.slutt.set({ hour: hour, minute: minutes });
                }
                data.slutt = data.slutt ? data.slutt.format('DD.MM.YYYY HH:mm') : null;

                if (data.paameldingsfrist) {
                    data.paameldingsfrist = data.paameldingsfrist.format('DD.MM.YYYY HH:mm');
                }

                if (data.beskrivelse == null) {
                    data.beskrivelse = '';
                }

                const response = await this.request({
                    method: 'post',
                    url: '/aktivitet' + (data.id ? '/' + data.id : ''),
                    data: data,
                });

                if (response && typeof response.id != 'undefined') {
                    if (this.form.id) {
                        this.snackbar('Lagret');

                        this.$emit('input', await this.formatActivity(response));
                        this.update(response);
                    } else {
                        this.snackbar('Opprettet');
                        this.$router.push({ path: '/arrangement/' + response.id });
                    }
                }
            }
        },

        /**
         * remove
         */
        remove: async function () {
            if (await this.confirm({ title: 'Slette?', message: 'Sikker på at du vil slette aktiviteten?' })) {
                const response = await this.request({
                    method: 'delete',
                    url: '/aktivitet/' + this.form.id,
                });
                if (response === true) {
                    this.snackbar('Slettet');
                    this.$router.push({ name: '/arrangementer' });
                }
            }
        },

        /*
         * cancel
         */
        cancel: function () {
            if (this.value) {
                this.form = Object.assign(this.form, this.value);
                this.update();
            }
        },

        /**
         * updateAvailableRooms
         */
        updateAvailableRooms: async function () {
            if (this.form.start && this.form.start_tid && this.form.slutt && this.form.slutt_tid) {
                if (!this.henterMoterom) {
                    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

                    this.henterMoterom = true;
                    await sleep(250);

                    const start = this.combineDate(this.form.start, this.form.start_tid);
                    const end = this.combineDate(this.form.slutt, this.form.slutt_tid);
                    let inkluder = null;
                    if (this.value && this.value.moterom && this.value.start && this.value.slutt) {
                        if (
                            start.isSameOrAfter(this.value.start) &&
                            start.isSameOrBefore(this.value.slutt) &&
                            end.isSameOrAfter(this.value.start) &&
                            end.isSameOrBefore(this.value.slutt)
                        ) {
                            inkluder = this.value.moterom.id;
                        }
                    }

                    const response = await this.request({
                        method: 'get',
                        url: '/moterom/ledig',
                        params: {
                            fra: start.format('DD.MM.YYYY HH:mm'),
                            til: end.format('DD.MM.YYYY HH:mm'),
                            inkluder: inkluder,
                        },
                    });

                    if (response && Array.isArray(response)) {
                        this.moterom = response;
                    } else {
                        this.moterom = [];
                    }
                    this.moterom.unshift({ id: null, navn: '(Ingen)' });

                    this.henterMoterom = false;
                }
            }
        },

        /**
         * validateEndDay
         */
        validateEndDay: function () {
            const start = this.combineDate(this.form.start, '');
            const end = this.combineDate(this.form.slutt, '');
            if (start && end && start > end) {
                return 'Må være etter start';
            }
            return true;
        },

        /**
         * validateEndTime
         */
        validateEndTime: function () {
            const start = this.combineDate(this.form.start, this.form.start_tid);
            const end = this.combineDate(this.form.slutt, this.form.slutt_tid);
            if (start && end && start > end) {
                return 'Må være etter start';
            }
            return true;
        },

        /**
         * combineDate
         */
        combineDate: function (date, time) {
            if (date) {
                date = date.clone();

                if (time) {
                    let hour, minutes;
                    [hour, minutes] = time.split(':');
                    date.set({ hour: hour, minute: minutes });
                } else {
                    date.set({ hour: 0, minute: 0 });
                }
            }
            return date;
        },
    },
};
</script>
